import {
	createUser,
	createUserFail,
	getUsers,
	getUsersFail,
	deleteUserFail,
	deleteUser,
	modifyUserFail,
	clearUserCreateErrors,
} from '../../store/slices/usersSlice';
import { sorbunuApi } from '../../api/axios';
import { endPoints } from '../../endPoints';
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'redux-first-history';

function* getStaffUsers(action) {
	try {
		const response = yield call(() =>
			sorbunuApi.get(endPoints.users.GET_STAFF_USERS(action.payload.sorting)),
		);

		yield put(getUsers(response.data));
	} catch (err) {
		if (err.response.status === 400) {
			if (err.response.data.errors) {
				yield put(getUsersFail(err.response.data.errors));
			} else {
				yield put(getUsersFail(err.response.data.non_field_errors));
			}
		} else {
			yield put(getUsersFail(err.response.data.details));
		}
	}
}
function* createNewStaffUser(action) {
	yield put(clearUserCreateErrors());
	try {
		const response = yield call(() =>
			sorbunuApi.post(endPoints.users.CREATE_STAFF_USER(), action.payload),
		);
		yield put(createUser(response.data));
		yield put(push(`/users/${response.data.nanoid}`));
	} catch (err) {
		yield put(createUserFail(err.response.data));
	}
}
function* deleteStaffUser(action) {
	try {
		yield call(
			() =>
				sorbunuApi.patch(
					endPoints.users.DELETE_STAFF_USER(action.payload.nanoid),
					action.payload.values,
				),
			yield put(deleteUser()),
			yield put(push(`/users/`)),
		);
	} catch (err) {
		if (err.response.status === 400) {
			if (err.response.data.errors) {
				yield put(deleteUserFail(err.response.data.errors));
			} else {
				yield put(deleteUserFail(err.response.data.non_field_errors));
			}
		} else {
			yield put(deleteUserFail(err.response.data.detail));
		}
	}
}
function* deactivateStaffUser(action) {
	try {
		yield call(() =>
			sorbunuApi.patch(
				endPoints.users.MODIFY_STAFF_USER(action.payload.nanoid),
				action.payload.values,
			),
		);
	} catch (err) {
		if (err.response.status === 400) {
			if (err.response.data.errors) {
				yield put(modifyUserFail(err.response.data.errors));
			} else {
				yield put(modifyUserFail(err.response.data.non_field_errors));
			}
		} else {
			yield put(modifyUserFail(err.response.data.detail));
		}
	}
}

export function* usersModuleSaga() {
	yield takeLatest('users/get_staff_users', getStaffUsers);
	yield takeLatest('users/create_new_staff_users', createNewStaffUser);
	yield takeLatest('users/delete_staff_user', deleteStaffUser);
	yield takeLatest('users/deactivate_staff_user', deactivateStaffUser);
}
