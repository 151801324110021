import { useDispatch } from 'react-redux';
import Badge from '../../components/Badge';

const UserDetailsCard = ({ user, deleteBtnHandler }) => {
	const isUserActive = user.is_active;
	const dispatch = useDispatch();

	const deactivateBtnHandler = isUserActive => {
		dispatch({
			type: 'users/deactivate_staff_user',
			payload: {
				nanoid: user.nanoid,
				values: {
					is_active: !isUserActive,
				},
			},
		});
	};

	return (
		<main className="py-10">
			<div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
				<div className="flex items-center space-x-5">
					<div className="flex-shrink-0">
						<div className="relative">
							{user.profile_picture ? (
								<img
									className="h-16 w-16 rounded-full"
									src={user.profile_picture}
									alt=""
								/>
							) : (
								<svg
									className="h-16 w-16 rounded-full bg-white text-gray-300"
									fill="currentColor"
									viewBox="0 0 24 24"
								>
									<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
								</svg>
							)}
						</div>
					</div>
					<div>
						<h1 className="text-2xl font-bold text-gray-900">
							{user.first_name} {user.last_name}
						</h1>
						<p className="text-sm font-medium text-gray-500">
							{user.company ? user.company.name : ''}
						</p>
					</div>
				</div>
				<div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
					{isUserActive ? (
						<button
							type="button"
							className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
							onClick={deactivateBtnHandler}
						>
							Deactivate
						</button>
					) : (
						<button
							type="button"
							className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-gray-100"
							onClick={deactivateBtnHandler}
						>
							Activate
						</button>
					)}
					<button
						type="button"
						className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
						onClick={deleteBtnHandler}
					>
						Delete User
					</button>
				</div>
			</div>

			<div className="mx-auto mt-8 grid max-w-7xl gap-6 sm:px-6 lg:max-w-7xl">
				<div className="space-y-6 lg:col-span-2 lg:col-start-1">
					<section aria-labelledby="applicant-information-title">
						<div className="bg-white shadow sm:rounded-lg">
							<div className="px-4 py-5 sm:px-6">
								<h2
									id="applicant-information-title"
									className="text-lg font-medium leading-6 text-gray-900"
								>
									Staff Information
								</h2>
								<p className="mt-1 max-w-2xl text-sm text-gray-500">
									Personal details are displayed below.
								</p>
							</div>
							<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
								<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">
											First Name
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											{user.first_name}
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">
											Last Name
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											{user.last_name}
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Email</dt>
										<dd className="mt-1 text-sm text-gray-900">{user.email}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Role</dt>
										<dd className="mt-1 text-sm text-gray-900">
											{user.is_superuser ? 'Admin' : 'Staff'}
										</dd>
									</div>
									<div>
										<dt className="text-sm font-medium text-gray-500">
											Company
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											{user.company ? user.company.name : ''}
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">
											Status
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											<Badge type={user.is_active ? 'success' : 'error'}>
												{user.is_active ? 'Active' : 'Deactive'}
											</Badge>
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">
											Offer Notifications
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											<Badge
												type={
													user.is_offer_notifications_enabled
														? 'success'
														: 'error'
												}
											>
												{user.is_offer_notifications_enabled
													? 'Active'
													: 'Deactive'}
											</Badge>
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">
											Project Notifications
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											<Badge
												type={
													user.is_project_notifications_enabled
														? 'success'
														: 'error'
												}
											>
												{user.is_project_notifications_enabled
													? 'Active'
													: 'Deactive'}
											</Badge>
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">
											Response Notifications
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											<Badge
												type={
													user.is_response_notifications_enabled
														? 'success'
														: 'error'
												}
											>
												{user.is_response_notifications_enabled
													? 'Active'
													: 'Deactive'}
											</Badge>
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">
											Verifed
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											<Badge type={user.is_verified ? 'success' : 'error'}>
												{user.is_verified ? 'Verifed' : 'Not Verified'}
											</Badge>
										</dd>
									</div>
								</dl>
							</div>
						</div>
					</section>
				</div>
			</div>
		</main>
	);
};

export default UserDetailsCard;
