import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	projectsList: [],
	projectDetail: {},
	error: '',
	status: 'all',
	currentPage: 1,
	sorting: '-created',
	comments: {},
};

export const projectsSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		getProjects: (state, action) => {
			(state.projectsList = action.payload),
				(state.filterProjectList = action.payload.results);
		},
		failGetProjects: (state, action) => {
			state.error = action.payload;
		},
		getProjectDetail: (state, action) => {
			state.projectDetail = action.payload;
		},
		changeStatus: (state, action) => {
			state.status = action.payload;
		},
		changeSorting: (state, action) => {
			state.sorting = action.payload;
		},
		getProjectComments: (state, action) => {
			state.comments = {
				...action.payload,
				results: [
					...(state.comments.results || []),
					...(action.payload.results || []),
				],
			};
		},
		setComments: state => {
			state.comments = {};
		},
	},
});

export const {
	getProjects,
	failGetProjects,
	getProjectDetail,
	changeStatus,
	filteredProjectList,
	changeSorting,
	getProjectComments,
	setComments,
} = projectsSlice.actions;

export default projectsSlice.reducer;
