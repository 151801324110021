import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserSort } from '../../components/Sorts/UserSort';

export default function Users() {
	const Navigate = useNavigate();
	const dispatch = useDispatch();
	const staffUsersList = useSelector(state => state.users.usersList.list);
	const sorting = useSelector(state => state.users.usersList.sorting);

	useEffect(() => {
		dispatch({
			type: 'users/get_staff_users',
			payload: {
				sorting,
			},
		});
	}, [dispatch, sorting]);

	const AddUserBtnHandler = () => {
		Navigate(`/users/create`);
	};

	const navigateDetailsHandler = nanoid => {
		Navigate(`/users/${nanoid}`);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-8 mt-10">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900">Users</h1>
					<p className="mt-2 text-sm text-gray-700">
						A list of all the users in your account including their name,
						surname, email and role.
					</p>
					<div className="flex items-center mt-8">
						<div className="mr-5">
							<UserSort sorting={sorting} />
						</div>
					</div>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<button
						type="button"
						className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
						onClick={AddUserBtnHandler}
					>
						Add user
					</button>
				</div>
			</div>
			<div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
				<table className="min-w-full divide-y divide-gray-300">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
							>
								First Name
							</th>
							<th
								scope="col"
								className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
							>
								Last Name
							</th>
							<th
								scope="col"
								className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
							>
								Email
							</th>
							<th
								scope="col"
								className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
							>
								Role
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						{staffUsersList.map(user => (
							<tr
								key={user.email}
								onClick={() => navigateDetailsHandler(user.nanoid)}
							>
								<td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
									{user.first_name}
									<dl className="font-normal lg:hidden">
										<dt className="sr-only">Title</dt>
										<dd className="mt-1 truncate text-gray-700">
											{user.last_name}
										</dd>
										<dt className="sr-only sm:hidden">Email</dt>
										<dd className="mt-1 truncate text-gray-500 sm:hidden">
											{user.email}
										</dd>
									</dl>
								</td>
								<td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
									{user.last_name}
								</td>
								<td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
									{user.email}
								</td>
								<td className="px-3 py-4 text-sm text-gray-500">
									{user.is_superuser ? 'Admin' : 'Staff'}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}
