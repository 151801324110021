import { useEffect } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProjectSort, PROJECT_STATUS_MAPPING } from '../../components/Sorts';
import Badge from '../../components/Badge';
import {
	ProjectFilterStatus,
	PROJECT_STATUS_TEXT_MAPPING,
} from '../../components/Filters';
import moment from 'moment';

export default function Projects() {
	const dispatch = useDispatch();
	const Navigate = useNavigate();

	const { auth } = useSelector(state => state.auth);

	const email = auth?.user.email;

	const { status, sorting, projectsList } = useSelector(
		state => state.projects,
	);
	const projects = projectsList.results;

	useEffect(() => {
		dispatch({
			type: 'projects/get_all_projects',
			payload: {
				status,
				sorting,
			},
		});
	}, [dispatch, status, sorting]);

	const handleRowClick = nanoid => {
		Navigate(`/projects/${nanoid}`);
	};
	return (
		<>
			<div>
				<div className="px-4 sm:px-6 lg:px-12 mt-10">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-xl font-semibold text-gray-900">Projects</h1>
							<p className="mt-2 text-sm text-gray-700">
								A list of all the users in your account including their name,
								title, email and role.
							</p>
							<div className="flex items-center mt-8">
								<div className="mr-5">
									<ProjectSort sorting={sorting} />
								</div>
								<div className="mr-5">
									<ProjectFilterStatus status={status} />
								</div>
							</div>
						</div>
					</div>
					<div className="mt-8 flex flex-col">
						<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								<div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
									<table className="min-w-full table-fixed divide-y divide-gray-300">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="min-w-[12rem] py-3.5 pr-3 pl-6 text-left text-sm font-semibold text-gray-900"
												>
													Project Title
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													Status
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													Modified Date
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													Company Name
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													Customer Email Address
												</th>
												<th
													scope="col"
													className="relative py-3.5 pl-3 pr-4 sm:pr-6"
												>
													<span className="sr-only">Edit</span>
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{projects &&
												projects.map(project => (
													<tr
														key={project.nanoid}
														className="cursor-pointer"
														onClick={() => handleRowClick(project.nanoid)}
													>
														<td className="pl-6">{project.title}</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
															<Badge
																type={PROJECT_STATUS_MAPPING[project.status]}
															>
																{project.status}
															</Badge>
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
															{moment(project.modified).format(
																'MMMM Do YYYY, h:mm:ss a',
															)}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
															{project.company.name}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
															{email}
														</td>
														<td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
															<button
																onClick={handleRowClick}
																className="text-indigo-600 hover:text-indigo-900"
															>
																view
																<span className="sr-only">
																	, {project.name}
																</span>
															</button>
														</td>
													</tr>
												))}
										</tbody>
									</table>
									{projects && projects.length === 0 && status === 'all' && (
										<div className="text-center my-5">
											<h3 className="mt-2 text-sm font-medium text-gray-900">
												Project not found
											</h3>
										</div>
									)}
									{projects && projects.length <= 0 && status !== 'all' && (
										<div className="flex items-center justify-center w-full my-5">
											There are no projects in{' '}
											<p className="text-indigo-400 ml-1 mr-1">
												{PROJECT_STATUS_TEXT_MAPPING[status]}
											</p>
											status
										</div>
									)}
								</div>
							</div>
							<div className="px-8 mt-1 mb-6">
								<nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
									<div className="-mt-px flex w-0 flex-1">
										<button
											disabled={!projectsList.previous}
											onClick={() =>
												dispatch({
													type: 'projects_page_previous',
													payload: projectsList.previous,
												})
											}
											className={`${
												!projectsList.previous
													? 'inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-300 hover:border-gray-500 cursor-not-allowed'
													: 'inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-500 cursor-pointer'
											}`}
										>
											<ArrowLeftIcon
												className={`${
													!projectsList.previous
														? 'mr-3 h-5 w-5 text-gray-300'
														: 'mr-3 h-5 w-5 text-gray-500'
												}`}
												aria-hidden="true"
											/>
											Previous
										</button>
									</div>
									<div className="-mt-px flex w-0 flex-1 justify-end">
										<button
											disabled={!projectsList.next}
											onClick={() =>
												dispatch({
													type: 'projects_page_next',
													payload: projectsList.next,
												})
											}
											className={`${
												!projectsList.next
													? 'inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-300 hover:border-gray-500 cursor-not-allowed'
													: 'inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-500 cursor-pointer'
											}`}
										>
											Next
											<ArrowRightIcon
												className={`${
													!projectsList.next
														? 'mr-3 h-5 w-5 text-gray-300'
														: 'mr-3 h-5 w-5 text-gray-500'
												}`}
												aria-hidden="true"
											/>
										</button>
									</div>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
