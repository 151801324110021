import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import {
	ChartBarIcon,
	FolderIcon,
	HomeIcon,
	InboxIcon,
	Bars3BottomLeftIcon,
	UsersIcon,
	XMarkIcon,
	TagIcon,
} from '@heroicons/react/24/outline';

import {
	CogIcon,
	ArrowRightOnRectangleIcon,
	MagnifyingGlassIcon,
} from '@heroicons/react/24/solid';
import { classNames } from '../../utils';
import {
	Link,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from 'react-router-dom';

import { useDispatch } from 'react-redux';
import Settings from '../Settings';
import { changeStatus } from '../../store/slices/projectsSlice';
import UserCreate from '../UserCreate';
import UserDetails from '../UserDetails';
import Users from '../Users';
import Dashboard from '../Dashboard';
import Projects from '../Projects';
import ProjectDetail from '../ProjectDetail';
import PromoCodes from '../PromoCodes';
import PromoCodeForm from '../PromoCodes/PromoCodeForm';
import { Logo } from '../../components/Logo';

const navigation = [
	{ name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false },
	{
		name: 'Projects',
		href: '/projects',
		icon: FolderIcon,
		children: [],
	},
	{ name: 'Audience Filter', href: '/', icon: UsersIcon, current: false },
	{ name: 'Customers', href: '/', icon: InboxIcon, current: false },
	{ name: 'Users', href: '/users', icon: ChartBarIcon, current: false },
	{
		name: 'Promo Codes',
		href: '/promo-codes',
		icon: TagIcon,
		current: false,
	},
];

export default function SignedInLayout() {
	const location = useLocation();
	const dispatch = useDispatch();
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const navigate = useNavigate();

	const handleLogOut = () => {
		dispatch({ type: 'auth/log_out' });
	};

	return (
		<>
			<div>
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-40 md:hidden"
						onClose={setSidebarOpen}
					>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
						</Transition.Child>

						<div className="fixed inset-0 flex z-40">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-indigo-700">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute top-0 right-0 -mr-12 pt-2">
											<button
												type="button"
												className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
												onClick={() => setSidebarOpen(false)}
											>
												<span className="sr-only">Close sidebar</span>
												<XMarkIcon
													className="h-6 w-6 text-white"
													aria-hidden="true"
												/>
											</button>
										</div>
									</Transition.Child>
									<div className="flex-shrink-0 flex items-center px-4">
										<Logo className="h-8 w-auto " color="#fff" />
									</div>
									<div className="mt-5 flex-1 h-0 overflow-y-auto">
										<nav className="px-2 space-y-1">
											{navigation.map(item => (
												<span
													key={item.name}
													onClick={() => navigate(item.href)}
													className={classNames(
														location.pathname.includes(item.name.toLowerCase())
															? 'bg-indigo-800 text-white'
															: 'text-indigo-100 hover:bg-indigo-600',
														'group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer',
													)}
												>
													<item.icon
														className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-300"
														aria-hidden="true"
													/>
													{item.name}
												</span>
											))}
										</nav>
									</div>
								</Dialog.Panel>
							</Transition.Child>
							<div className="flex-shrink-0 w-14" aria-hidden="true">
								{/* Dummy element to force sidebar to shrink to fit close icon */}
							</div>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex flex-col flex-grow pt-5 bg-indigo-700 overflow-y-auto">
						<div className="flex items-center flex-shrink-0 px-4">
							<Logo className="h-8 w-auto " color="#fff" />
						</div>
						<div className="mt-5 flex-1 flex flex-col">
							<nav className="flex-1 px-2 pb-4 space-y-1">
								{navigation.map(item =>
									!item.children ? (
										<span
											key={item.name}
											onClick={() => navigate(item.href)}
											className={classNames(
												location.pathname.includes(item.name.toLowerCase())
													? 'bg-indigo-800 text-white'
													: 'text-indigo-100 hover:bg-indigo-600',
												'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer',
											)}
										>
											<item.icon
												className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
												aria-hidden="true"
											/>
											{item.name}
										</span>
									) : (
										<Disclosure as="div" key={item.name} className="space-y-1">
											{({ open }) => (
												<>
													<Disclosure.Button
														className={classNames(
															location.pathname.includes(
																item.name.toLowerCase(),
															)
																? 'bg-indigo-800 text-white'
																: 'text-indigo-100 hover:bg-indigo-600',
															'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500',
														)}
													>
														<item.icon
															className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
															aria-hidden="true"
														/>
														<span className="flex-1">{item.name}</span>
														<svg
															className={classNames(
																open
																	? 'text-gray-400 rotate-90'
																	: 'text-gray-300',
																'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
															)}
															viewBox="0 0 20 20"
															aria-hidden="true"
														>
															<path
																d="M6 6L14 10L6 14V6Z"
																fill="currentColor"
															/>
														</svg>
													</Disclosure.Button>
													<Disclosure.Panel className="space-y-1">
														<Disclosure
															as="a"
															href="/projects"
															className="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-white"
														>
															All
														</Disclosure>
														<Disclosure
															as="a"
															onClick={() => {
																navigate('/projects');
																dispatch(changeStatus('under_approval'));
															}}
															className="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-white cursor-pointer"
														>
															Under Approval
														</Disclosure>
													</Disclosure.Panel>
												</>
											)}
										</Disclosure>
									),
								)}
							</nav>
						</div>
					</div>
				</div>
				<div className="md:pl-64 flex flex-col flex-1">
					<div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
						<button
							type="button"
							className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
							onClick={() => setSidebarOpen(true)}
						>
							<span className="sr-only">Open sidebar</span>
							<Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
						</button>
						<div className="flex-1 px-4 flex justify-between">
							<div className="flex-1 flex">
								<form className="w-full flex md:ml-0" action="#" method="GET">
									<label htmlFor="search-field" className="sr-only">
										Search
									</label>
									<div className="relative w-full text-gray-400 focus-within:text-gray-600">
										<div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
											<MagnifyingGlassIcon
												className="h-5 w-5"
												aria-hidden="true"
											/>
										</div>
										<input
											id="search-field"
											className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
											placeholder="Search"
											type="search"
											name="search"
										/>
									</div>
								</form>
							</div>
							<div className="ml-4 flex items-center md:ml-6">
								{/* Profile dropdown */}
								<Menu as="div" className="ml-3 relative">
									<div>
										<Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
											<span className="sr-only">Open user menu</span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												fill="currentColor"
												className="w-6 h-6"
											>
												<path
													fillRule="evenodd"
													d="M10.5 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
													clipRule="evenodd"
												/>
											</svg>
										</Menu.Button>
									</div>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items className="origin-top-right z-50 absolute right-0 mt-2 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
											<Menu.Item>
												<Link
													to={'/settings'}
													className="flex ml-3.5 mt-2 mb-2 text-gray-600"
												>
													<CogIcon className="w-5 mr-1" /> Settings
												</Link>
											</Menu.Item>

											<hr />
											<button
												onClick={handleLogOut}
												className="flex ml-4 mt-2 text-gray-600"
											>
												<ArrowRightOnRectangleIcon className="w-5 mr-1 " />
												Logout
											</button>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
						</div>
					</div>

					<Routes>
						<Route path="dashboard" element={<Dashboard />} />
						<Route path="projects" element={<Projects />} />
						<Route path="users" element={<Users />} />
						<Route path="users/:nanoid/" element={<UserDetails />} />
						<Route path="users/create/" element={<UserCreate />} />
						<Route path="settings" element={<Settings />} />
						<Route path="projects/:nanoid" element={<ProjectDetail />} />
						<Route path="promo-codes" element={<PromoCodes />} />
						<Route
							path="promo-codes/create"
							element={<PromoCodeForm mode="create" />}
						/>
						<Route
							path="promo-codes/edit/:nanoid"
							element={<PromoCodeForm mode="edit" />}
						/>
					</Routes>
				</div>
			</div>
		</>
	);
}
