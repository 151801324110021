import { object, string } from 'yup';
import { ERROR_MESSAGES } from '../../utils/error-messages';
const { required } = ERROR_MESSAGES;

export const updateMarketingDetailsSchema = object({
	title: string().nullable().min(3, 'Too Short').required(required('title')),
	description: string()
		.nullable()
		.min(3, 'Too Short')
		.required(required('description')),
});
