import {
	getProjects,
	getProjectDetail,
	getProjectComments,
} from '../../store/slices/projectsSlice';
import { sorbunuApi } from '../../api/axios';
import { endPoints } from '../../endPoints';
import { call, put, takeLatest } from 'redux-saga/effects';

function* getAllProjects(action) {
	const response = yield call(() =>
		sorbunuApi.get(
			endPoints.projects.GET_PROJECTS(
				action.payload.status,
				action.payload.sorting,
			),
		),
	);
	yield put(getProjects(response.data));
}

function* handleNextClick(action) {
	const response = yield call(() => sorbunuApi.get(action.payload));
	yield put(getProjects(response.data));
}

function* handlePrevClick(action) {
	const response = yield call(() => sorbunuApi.get(action.payload));
	yield put(getProjects(response.data));
}

function* retrieveProjects(action) {
	const response = yield call(() =>
		sorbunuApi.get(endPoints.projects.RETRIEVE_PROJECT(action.payload)),
	);
	yield put(getProjectDetail(response.data));
}

function* projectsMarketingDetails(action) {
	const response = yield call(() =>
		sorbunuApi.patch(
			endPoints.projects.PROJECTS_MARKETING(action.payload.nanoid),
			{
				marketing_title: action.payload.marketing_title,
				marketing_description: action.payload.marketing_description,
			},
		),
	);
	yield put(getProjectDetail(response.data));
}

function* updateStatusUnderEdit(action) {
	const response = yield call(() =>
		sorbunuApi.patch(endPoints.projects.UPDATE_STATUS(action.payload.nanoid), {
			status: action.payload.status,
		}),
	);

	const { nanoid } = response.data;
	yield call(retrieveProjects, { payload: nanoid });
}
function* updateStatusApproved(action) {
	const response = yield call(() =>
		sorbunuApi.patch(endPoints.projects.UPDATE_STATUS(action.payload.nanoid), {
			status: action.payload.status,
		}),
	);
	const { nanoid } = response.data;
	yield call(retrieveProjects, { payload: nanoid });
}
function* updateStatusPaused(action) {
	const response = yield call(() =>
		sorbunuApi.patch(endPoints.projects.UPDATE_STATUS(action.payload.nanoid), {
			status: action.payload.status,
		}),
	);
	const { nanoid } = response.data;

	yield call(retrieveProjects, { payload: nanoid });
}
function* updateStatusCompleted(action) {
	const response = yield call(() =>
		sorbunuApi.patch(endPoints.projects.UPDATE_STATUS(action.payload.nanoid), {
			status: action.payload.status,
		}),
	);
	const { nanoid } = response.data;
	yield call(retrieveProjects, { payload: nanoid });
}
function* getComments(action) {
	const response = yield call(() =>
		sorbunuApi.get(endPoints.projects.COMMENTS(action.payload)),
	);
	yield put(getProjectComments(response.data));
}
function* commentsNext(action) {
	const response = yield call(() => sorbunuApi.get(action.payload));
	yield put(getProjectComments(response.data));
}

export function* projectsModuleSaga() {
	yield takeLatest('projects/get_all_projects', getAllProjects);
	yield takeLatest('projects_page_next', handleNextClick);
	yield takeLatest('projects_page_previous', handlePrevClick);
	yield takeLatest('projects_detail_page', retrieveProjects);
	yield takeLatest('projects_marketing-details', projectsMarketingDetails);
	yield takeLatest('projects_status_under_edit', updateStatusUnderEdit);
	yield takeLatest('projects_status_approved', updateStatusApproved);
	yield takeLatest('projects_status_paused', updateStatusPaused);
	yield takeLatest('projects_status_completed', updateStatusCompleted);
	yield takeLatest('projects_comments', getComments);
	yield takeLatest('projects_comments_next', commentsNext);
}
