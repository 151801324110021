export const endPoints = {
	auth: {
		SIGN_IN: () => '/auth/login/',
		LOG_OUT: () => '/auth/logout/',
	},
	projects: {
		GET_PROJECTS: (status, sorting) =>
			`/projects/?ordering=${sorting}${
				status === 'all' ? '' : `&status=${status}`
			}`,
		RETRIEVE_PROJECT: nanoid => `/projects/${nanoid}/detailed`,
		PROJECTS_MARKETING: nanoid => `/projects/${nanoid}/marketing-info/`,
		UPDATE_STATUS: nanoid => `/projects/${nanoid}/`,
		COMMENTS: nanoid => `/projects/${nanoid}/comments/`,
	},
	users: {
		GET_STAFF_USERS: sorting =>
			`/users/staff/?is_anonymized=false&ordering=${sorting}`,
		CREATE_STAFF_USER: () => '/users/',
		DELETE_STAFF_USER: nanoid => `/users/${nanoid}/anonymize/`,
		MODIFY_STAFF_USER: nanoid => `/users/${nanoid}/`,
	},
	promoCodes: {
		GET_PROMO_CODES: () => '/promo-codes/',
		CREATE_PROMO_CODE: () => '/promo-codes/',
		UPDATE_PROMO_CODE: nanoid => `/promo-codes/${nanoid}`,
	},
	dashboard: {
		GET_COMPLETED_PROJECTS_ON_TIME: () =>
			'bo-analysis/projects-completed-on-time/',
		GET_ACCOUNTS_WITH_AT_LEAST_ONE_PROJECT: () =>
			'bo-analysis/accounts-with-at-least-one-project/',
		GET_RESPONSE_TIME_SUCCESS_RATE: () =>
			'bo-analysis/response-time-success-rate/',
		GET_UNDER_APPROVAL_PROJECTS: () => 'projects/?status=under_approval',
		GET_PROJECTS_WITHOUT_ACTION: () => 'projects/get-without-action-projects/',
		GET_PROJECTS_NEARING_ESTIMATED_TIME: () =>
			'projects/get-projects-nearing-estimated-time/',
	},
};
