import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { updateMarketingDetailsSchema } from './constant';
import { Formik, Form } from 'formik';
import InfiniteScroll from 'react-infinite-scroller';
import { setComments } from '../../store/slices/projectsSlice';
import NiceModal from '@ebay/nice-modal-react';
import Modal from '../../components/Modal';
import { Buttons } from '../../components/Button';
import { FormInput } from '../../components/TextInput';

export default function ProjectDetail() {
	const dispatch = useDispatch();
	const { nanoid } = useParams();
	const { projectDetail, comments } = useSelector(state => state.projects);
	const { auth } = useSelector(state => state?.auth);
	const user = auth?.user;

	useEffect(() => {
		dispatch({ type: 'projects_detail_page', payload: nanoid });
		dispatch({
			type: 'projects_comments',
			payload: nanoid,
		});
	}, [dispatch, nanoid]);

	const loadFunc = () => {
		if (comments.next !== null) {
			dispatch({ type: 'projects_comments_next', payload: comments.next });
		}
	};

	const handleApproveButtonClick = () => {
		NiceModal.show(Modal, {
			title: 'Are you sure?',
			message:
				'By approving the project, you will open the survey to the respondents and have withdrawn the fee of the research from the provision.',
			showPrimaryButton: true,
			primaryButtonLabel: "I'm Sure",
			secondaryButtonLabel: 'Cancel',
			onPrimaryButtonClick: async () => {
				dispatch({
					type: 'projects_status_approved',
					payload: {
						nanoid,
						status: 'approved',
					},
				});
			},
		});
	};

	const handlePauseButtonClick = () => {
		NiceModal.show(Modal, {
			title: 'Reason for stopping the project',
			message:
				'By pausing the project, the survey will have frozen the response collection process and no answers will be collected until it is reopened. Are you sure to continue?',
			showPrimaryButton: true,
			showTextArea: true,
			primaryButtonLabel: "I'm Sure",
			secondaryButtonLabel: 'Cancel',
			checkboxLabel: 'Notify the customer about status change',
			onPrimaryButtonClick: () => {
				dispatch({
					type: 'projects_status_paused',
					payload: {
						nanoid,
						status: 'paused',
					},
				});
			},
		});
	};

	const handleCompleteButtonClick = () => {
		NiceModal.show(Modal, {
			title: 'Reason for completing the project',
			message:
				'By completing the project, the survey will stop and no answers will be collected until it is reopened. THIS ACTION CANNOT BE UNDONE. Are you sure to continue?',
			showPrimaryButton: true,
			showTextArea: true,
			primaryButtonLabel: "I'm Sure",
			secondaryButtonLabel: 'Cancel',
			checkboxLabel: 'Notify the customer about status change',
			onPrimaryButtonClick: () => {
				dispatch({
					type: 'projects_status_completed',
					payload: {
						nanoid,
						status: 'completed',
					},
				});
			},
		});
	};

	const updateMarketingDetails = values => {
		dispatch({
			type: 'projects_marketing-details',
			payload: {
				nanoid,
				marketing_title: values.title,
				marketing_description: values.description,
			},
		});
	};
	return (
		<>
			<div className="min-h-full">
				<main className="py-10">
					<div className="mt-4 grid max-w-3xl grid-cols-1 gap-6 sm:px-12 lg:max-w-full lg:grid-flow-col-dense lg:grid-cols-3">
						<div className="space-y-6 lg:col-span-2 lg:col-start-1">
							<section aria-labelledby="project-information-title">
								<div className="bg-white shadow sm:rounded-lg">
									<div className="px-4 py-5 sm:px-6">
										<h2
											id="project-information-title"
											className="text-lg font-medium leading-6 text-gray-900"
										>
											Project Details
										</h2>
										<p className="mt-1 max-w-2xl text-sm text-gray-500">
											See project details and update title and description seen
											by Denebunu users.
										</p>
									</div>
									<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
										<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-black">
													Project Title
												</dt>
												<dd className="mt-1 text-sm text-gray-900 capitalize">
													{projectDetail.title}
												</dd>
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-black">
													Project Status
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{projectDetail.status}
												</dd>
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-black">
													Create Date
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{moment(projectDetail.created).format(
														'MMMM Do YYYY, h:mm:ss a',
													)}
												</dd>
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-black">
													Modified Date
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{moment(projectDetail.modified).format(
														'MMMM Do YYYY, h:mm:ss a',
													)}
												</dd>
											</div>
											<div className="sm:col-span-2">
												<dt className="text-sm font-medium text-black">
													Company Name
												</dt>
												<dd className="mt-1 text-sm text-gray-900 capitalize">
													{projectDetail?.company?.name}
												</dd>
											</div>
										</dl>
										<dt className="text-md font-medium text-black sm:col-span-3 mt-10">
											Customer
										</dt>
										<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 mt-1">
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-black">
													First Name
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{user?.first_name}
												</dd>
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-black">
													Last Name
												</dt>
												<dd className="mt-1 text-sm text-black">
													{user?.last_name}
												</dd>
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-black">
													Email
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{user?.email}
												</dd>
											</div>
										</dl>
									</div>
									<hr />
									<div>
										<Formik
											initialValues={{
												title: projectDetail.marketing_title || '',
												description: projectDetail.marketing_description || '',
											}}
											enableReinitialize={true}
											validateOnChange={true}
											validationSchema={updateMarketingDetailsSchema}
											onSubmit={values => {
												updateMarketingDetails(values);
											}}
										>
											{props => (
												<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10 px-6">
													<Form>
														<div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:pt-5">
															<label
																htmlFor="title"
																className="block text-sm font-medium sm:col-span-2 text-gray-900 sm:pt-3 "
															>
																Marketing Title
															</label>
															<div className="mt-2 sm:col-span-2 sm:mt-0">
																<FormInput
																	id="title"
																	name="title"
																	type="text"
																	className="block w-full text-sm max-w-lg rounded-md border-gray-500 py-1.5 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none "
																/>
															</div>
														</div>

														<div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4  sm:pt-5">
															<label
																htmlFor="description"
																className="block text-sm font-medium sm:col-span-2 leading-6 text-gray-900 sm:pt-3"
															>
																Marketing Description
															</label>
															<div className="mt-2 sm:col-span-2 sm:mt-0 pb-2">
																<FormInput
																	id="description"
																	name="description"
																	type="text"
																	className="block w-full text-sm max-w-lg rounded-md border-gray-500 py-1.5 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none "
																/>
															</div>
														</div>
														<div className="text-right pb-3">
															<button
																type="submit"
																disabled={!props.isValid || !props.dirty}
																className="disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
															>
																Save
															</button>
														</div>
													</Form>
												</div>
											)}
										</Formik>
									</div>
								</div>
							</section>
							<section aria-labelledby="comments-title">
								<div className="bg-white h-1/4 shadow sm:overflow-hidden sm:rounded-lg">
									<div className="divide-y divide-gray-200">
										<div className="px-4 py-5 sm:px-6 flex justify-between">
											<h2
												id="comments-title"
												className="flex text-lg font-medium text-gray-900"
											>
												Comments
											</h2>
											<button
												onClick={() => {
													dispatch(setComments());
													dispatch({
														type: 'projects_comments',
														payload: nanoid,
													});
												}}
											>
												<ArrowPathIcon className="w-5 h-5" />
											</button>
										</div>
										<hr />
									</div>
									<div className="divide-y divide-gray-200">
										<div className="h-96 overflow-auto px-4 py-5 sm:px-6 mb-3">
											{comments.results && (
												<InfiniteScroll
													useWindow={false}
													pageStart={0}
													loadMore={loadFunc}
													hasMore={!!comments.next}
													loader={
														<div className="loader" key={0}>
															Loading ...
														</div>
													}
												>
													{comments.results.map((item, index) => (
														<div key={index}>
															<div className="flex items-center mb-3">
																{item.user.profile_picture ? (
																	<img className="bg-gray-300 rounded-full w-8 h-8" />
																) : (
																	<div className="bg-indigo-300 rounded-full w-8 h-8 flex items-center justify-center text-white">
																		{item.user.first_name
																			?.charAt(0)
																			?.toUpperCase() +
																			item.user.last_name
																				?.charAt(0)
																				?.toUpperCase()}
																	</div>
																)}

																<div className="ml-5 capitalize font-medium font-semibold">
																	{item.user.first_name}
																</div>
																<p className="ml-5 text-xs">
																	{moment(item.modified).format(
																		'MMMM Do YYYY, h:mm:ss a',
																	)}
																</p>
															</div>
															<p className="text-sm mb-3">{item.body}</p>
															<hr className="mb-5" />
														</div>
													))}
												</InfiniteScroll>
											)}
										</div>
										<hr />
									</div>
								</div>
							</section>
						</div>

						<section
							aria-labelledby="status-buttons"
							className="lg:col-span-1 lg:col-start-3"
						>
							<div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-4">
								<a
									target="_blank"
									href={`${process.env.REACT_APP_PROJECT_DOMAIN_URL}/project/${projectDetail.nanoid}/audience`}
									rel="noreferrer"
								>
									<Buttons fullWidth>See Project in Sorbunu</Buttons>
								</a>
								<div className="flex">
									<div className="mt-3 w-1/2 mr-1">
										{projectDetail.status === 'under_approval' && (
											<button
												onClick={() =>
													dispatch({
														type: 'projects_status_under_edit',
														payload: {
															nanoid,
															status: 'under_edit',
														},
													})
												}
												className="bg-yellow-400 text-white text-xs shadow-sm rounded-lg w-full p-2 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:hover:bg-yellow-400 disabled:opacity-60 focus:ring-yellow-500"
												disabled={
													!(
														projectDetail.marketing_description &&
														projectDetail.marketing_title
													)
												}
											>
												Under Edit: Give Revision
											</button>
										)}
										{projectDetail.status === 'running' && (
											<button
												onClick={handlePauseButtonClick}
												className="bg-orange-400 text-white text-xs shadow-sm rounded-lg w-full p-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
											>
												Pause the Project
											</button>
										)}
										{projectDetail.status === 'paused' && (
											<button className="bg-green-400 text-white text-xs shadow-sm rounded-lg w-full p-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
												Rerun the Project
											</button>
										)}
									</div>
									<div className="mt-3 w-1/2 ml-1">
										{projectDetail.status === 'under_approval' && (
											<button
												className="bg-green-600 text-white text-xs rounded-lg shadow-sm w-full p-2 disabled:cursor-not-allowed disabled:hover:bg-green-600 disabled:opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
												onClick={handleApproveButtonClick}
												disabled={
													!(
														projectDetail.marketing_description &&
														projectDetail.marketing_title
													)
												}
											>
												Approve: Approve and Launch
											</button>
										)}
										{projectDetail.status === 'running' && (
											<button
												onClick={handleCompleteButtonClick}
												className="bg-red-400 text-white text-xs shadow-sm rounded-lg w-full p-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
											>
												Complete the Project
											</button>
										)}
									</div>
								</div>
							</div>
						</section>
					</div>
				</main>
			</div>
		</>
	);
}
