import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormAlert from '../../components/FormAlert';
import Notification from '../../components/Notification';
import { FormInput } from '../../components/TextInput';
import { userCreateValidationSchema } from './constant';
import { UserCreateValues } from './InitialValues';

export default function UserCreate() {
	const errorMessages = useSelector(state => state.users.newUser.error);
	const [imageUrl, setImageUrl] = useState();
	const [image, setImage] = useState('');
	const [showErrorNotifications, setShowErrorNotifications] = useState(false);
	const dispatch = useDispatch();
	const fileInputRef = useRef();

	const imageUploadHandler = e => {
		setImage(e.target.files[0]);
	};

	useEffect(() => {
		image && setImageUrl(URL.createObjectURL(image));
	}, [image]);

	const getBase64 = file => {
		return new Promise(resolve => {
			let baseURL = '';
			let reader = new FileReader();
			if (file) {
				reader.readAsDataURL(file);
			}

			reader.onload = () => {
				baseURL = reader.result;
				resolve(baseURL);
			};
		});
	};

	const payloadValues = (values, base64) => {
		if (base64) {
			return {
				...values,
				file: base64,
			};
		}
		return values;
	};

	const formSubmissionHandler = async values => {
		const base64 = image && (await getBase64(image));
		dispatch({
			type: 'users/create_new_staff_users',
			payload: payloadValues(values, base64),
		});
	};

	useEffect(() => {
		errorMessages.detail && setShowErrorNotifications(true);
		setTimeout(() => {
			setShowErrorNotifications(false);
		}, 3000);
	}, [errorMessages.detail]);

	return (
		<div className="bg-white mx-8 p-8 shadow-md rounded-lg sm:px-6 lg:px-8 mt-10">
			<div className="space-y-8 divide-y divide-gray-200">
				{errorMessages.nonFieldErrors && (
					<FormAlert
						errorMessage={errorMessages.nonFieldErrors.nonFieldErrors}
					/>
				)}
				<div className="space-y-8 divide-y divide-gray-200">
					<Formik
						initialValues={UserCreateValues}
						onSubmit={values => {
							formSubmissionHandler(values);
						}}
						validateOnChange={true}
						validationSchema={userCreateValidationSchema}
					>
						<Form>
							<div>
								<div>
									<h3 className="text-lg font-medium leading-6 text-gray-900">
										Personal Information
									</h3>
									<p className="mt-1 text-sm text-gray-500">
										Please fill out the form completely.
									</p>
								</div>
								<div className="sm:col-span-6 mt-10">
									<div className="mt-1 flex items-center">
										<span className="h-20 w-20 overflow-hidden rounded-full bg-gray-100">
											{imageUrl && (
												<img
													type="file"
													className="object-cover w-20 h-20 shadow-lg rounded-full"
													src={imageUrl}
												/>
											)}
											<svg
												className="h-full w-full text-gray-300"
												fill="currentColor"
												viewBox="0 0 24 24"
											>
												<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
											</svg>
										</span>
										<button
											type="button"
											className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
											onClick={() => fileInputRef.current.click()}
										>
											Add Picture
										</button>
										<input
											onChange={imageUploadHandler}
											multiple={false}
											accept="image/*"
											ref={fileInputRef}
											type="file"
											hidden
										/>
									</div>
								</div>
								<div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
									<div className="sm:col-span-3">
										<label
											htmlFor="first-name"
											className="block text-sm font-medium text-gray-700"
										>
											First name
										</label>
										<div className="mt-1">
											<FormInput
												type="text"
												name="first_name"
												className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											/>
										</div>
									</div>

									<div className="sm:col-span-3">
										<label
											htmlFor="last-name"
											className="block text-sm font-medium text-gray-700"
										>
											Last name
										</label>
										<div className="mt-1">
											<FormInput
												type="text"
												name="last_name"
												className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											/>
										</div>
									</div>

									<div className="sm:col-span-3">
										<label
											htmlFor="email"
											className="block text-sm font-medium text-gray-700"
										>
											Email address
										</label>
										<div className="mt-1">
											<FormInput
												type="email"
												name="email"
												className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											/>
										</div>
										{errorMessages.errors &&
											errorMessages.errors.errors.email.map((error, ind) => (
												<div
													key={ind}
													className="flex mt-2 items-center pointer-events-none"
												>
													<ExclamationCircleIcon
														className="h-5 w-5 text-red-500"
														aria-hidden="true"
													/>
													<p
														className="text-sm ml-2 text-red-600"
														id="email-error"
													>
														{error}
													</p>
												</div>
											))}
									</div>
								</div>
								<div className="pt-5">
									<div className="flex justify-end">
										<button
											type="button"
											className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										>
											Cancel
										</button>
										<button
											type="submit"
											className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										>
											Save
										</button>
									</div>
								</div>
							</div>
						</Form>
					</Formik>
				</div>
			</div>
			<Notification
				show={showErrorNotifications}
				setShow={setShowErrorNotifications}
				message={errorMessages && errorMessages.detail.detail}
				type={errorMessages ? 'error' : 'success'}
			/>
		</div>
	);
}
